.home-section-container {
    position: relative;
    height: 768px;
    background-size: cover;
}

.home-container {
    max-width: 768px;
    margin-top: 72px;
    padding-left: 16.3%;
    /*padding-right: 30.3%;*/
    padding-top: 120px;
}

.home-title {
    font-family: HCo Chronicle Display, 'Playfair Display', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 78px;
    line-height: 150%;
    color: white;
}

.home-title-ru {
    line-height: 100%;
}

.home-subtitle {
    max-width: 637px;
    padding: 0;
    filter: drop-shadow(0px 16px 24px rgba(49, 86, 105, 0.2));
    border-radius: 16px;
    margin: 10px 0;
}

.home-subtitle h5 {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 2px;
    color: #E5EFF2;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.home-subtitle p {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #E5EFF2;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 24px 0;
}

.home-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 0;
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none !important;
    margin-top: 3rem;
    width: 343px;
    color: white;

    background: linear-gradient(90deg, #C6B166 0%, #A9955E 100%);
    box-shadow: 0 16px 24px rgba(49, 86, 105, 0.2);
    border-radius: 50px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.home-button:hover {
    color: white !important;
}

@media screen and (max-width: 425px) {
    .home-container {
        padding-top: 5.2% !important;
    }
}

@media screen and (max-width: 1024px) {
    .home-container {
        /*max-width: 637px;*/
        margin-top: 48px;
        padding-top: 12%;
        padding-left: 4.26%;
        padding-right: 4.26%;
    }

    .home-title {
        padding: 0 23px;
        font-size: 48px;
        line-height: 150%;
    }

    .home-subtitle {
        align-items: center;
        /*background: #305C6E;*/
        padding: 25px 23px;
        border-radius: 8px;
        max-width: none;
    }

    .home-button {
        margin-top: 1rem;
        padding: 16px 0;
        letter-spacing: 0.01em;
        width: 100%;
    }

    .home-subtitle h5 {
        font-size: 20px;
        letter-spacing: 1px;
    }

    .home-subtitle p {
        font-size: 14px;
        line-height: 180%;
    }
}
