.expertise-areas-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url(/images/big-world.png);
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 380px;
    position: relative;
    z-index: 1;
}

.expertise-areas-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    margin-top: 80px;
}

#expertise-areas-title {
    text-align: center;
    width: 35vw;
    max-width: 800px;
    margin-top: 30px;
}

#expertise-card-container {
    display: flex;
    margin: -12px;
    margin-bottom: 120px;
}

.expertise-column {
    display: flex;
    flex-direction: column;
}

#presentation-paragraph {
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    padding-bottom: 20px;
    max-width: 550px;
}

#expertise-areas-title-mobile {
    display: none;
}

@media screen and (max-width: 1024px) {
    .expertise-areas-container {
        background: none;
    }

    .expertise-areas-title-container {
        margin: 82px 20px 60px 20px;
    }

    .expertise-column > #presentation-paragraph {
        margin: 0 30px 24px 30px;
        padding-bottom: 0;
    }

    #expertise-areas-title {
        font-size: 36px;
        line-height: 54px;
        width: 100%;
        min-width: auto;
    }

    #expertise-card-container {
        flex-direction: column;
        margin: 0;
        margin-bottom: 40px;
        align-items: center;
    }

    .expertise-column {
        width: 100%;
    }

    .expertise-card-container *, *::before, *::after {
        box-sizing: border-box;
    }
}
