.customers-section {
    margin-top: 70px;
    margin-bottom: 23vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.customers-section .section-title {
    font-size: 48px;
    line-height: 57px;
}

#left-container {
    width: 60%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
}

#customers-text-container {
    width: 30%;
    z-index: 10;
}

.customer-image {
    max-width: 388px;
    width: 25vw;
}

#smaller-image {
    object-fit: scale-down;
    margin-left: 20px;
    margin-bottom: 20px;
}

.signature-second-row {
    font-weight: 400;
}

#customer-quote {
    position: absolute;
    right: 12%;
    bottom: -100px;
}

@media screen and (min-width: 1650px) {
    #customer-quote {
        right: 18%;
    }
}

@media screen and (min-width: 1850px) {
    #customer-quote {
        right: 28%;
    }
}

.customer-images-container {
    display: flex;
    align-items: flex-start;
}

#get-in-touch-mobile {
    display: none;
}

@media screen and (max-width: 1024px) {
    .customers-section {
        flex-direction: column-reverse;
        margin-bottom: 60px;
    }

    .customers-section .section-title {
        font-size: 28px;
        line-height: 42px;
    }

    #left-container {
        position: relative;
        margin-top: 0;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    #left-container #get-in-touch-mobile, #get-in-touch-mobile::before, #get-in-touch-mobile::after {
        box-sizing: border-box;
    }

    .customer-images-container {
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
        width: 100vw;
    }

    #customer-quote {
        position: relative;
        right: auto;
        bottom: auto;
        margin-top: -50px;
        margin-bottom: 40px;
    }

    #customers-text-container {
        width: 90%;
    }

    .customer-image {
        margin-left: 5px;
        margin-right: 5px;
        max-width: 50vw;
        width: auto;
    }

    #smaller-image {
        margin-left: 0;
    }

    #bigger-image {
        margin-right: 0;
    }

    #get-in-touch-desktop {
        display: none;
    }

    #get-in-touch-mobile {
        display: block;
        width: 100%;
    }
}
