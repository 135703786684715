.percentage-section-container {
    margin-top: 120px;
    margin-bottom: 70px;
}

.percentage-section-container .section-title {
    font-size: 48px;
    line-height: 72px;
    font-weight: 600;
}

#horizontal-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#percentage-title-container {
    /*width: 60%;*/
    margin-top: -25px;
}

#percentage-container {
    display: flex;
    justify-content: space-between;
    margin-left: -40px;
    margin-top: 50px;
    margin-bottom: 60px;
    margin-right: -60px;
}

.percentage-column {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    max-width: 193px;
}

.percentage-column {
    text-align: center;
}

.percentage-column .medium-paragraph-14 {
    text-align: start;
}

.number {
    font-family: HCo Chronicle Display, 'Playfair Display', serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    color: #C6B166;
}

#person-container {
    width: 40%;
    /*margin-left: 50px;*/
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
}

.person-container-homepage{
    width: 30% !important;
}

@media screen and (max-width: 1359px) {
    #percentage-container {
        flex-direction: column;
    }
}

.image-container {
    height: 100%;
}

.person-image {
    max-height: 400px;
    object-fit: cover;
    max-width: 400px;
    width: 28vw;
}

#person-text-container {
    position: relative;
    right: 60px;
    bottom: 0;
    top: -60px;
}

#person-title {
    font-weight: 500;
}

#percentage-contact-consultants-mobile {
    display: none;
}

@media screen and (max-width: 1024px) {
    .percentage-section-container {
        margin-top: 80px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #horizontal-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .details-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        position: relative;
        width: 90%;
    }

    #percentage-contact-consultants-desktop {
        display: none;
    }

    #percentage-contact-consultants-mobile {
        display: block;
        box-sizing: border-box;
        margin-top: -20px;
    }

    #percentage-container {
        margin-top: 0;
        margin-bottom: 40px;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
    }

    .percentage-column {
        align-items: center;
        margin-left: 0;
        text-align: center;
        margin-top: 20px;
    }

    .percentage-column .medium-paragraph-14 {
        color: #305C6E;
    }

    .number {
        margin-bottom: 10px;
    }

    #person-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;
        margin-left: auto;
    }

    .image-container {
        position: relative;
        left: 14vw;
    }

    .person-image {
        max-width: 375px;
        width: 90%;
    }

    #person-container *, *:before, *:after {
        box-sizing: border-box;
    }

    #person-text-container {
        right: auto;
        width: 100%;
    }

    #percentage-title-container {
        /*width: 90%;*/
    }

    .percentage-section-container .section-title {
        font-size: 28px;
        line-height: 42px;
    }
}


@media screen and (min-width: 550px) and (max-width: 750px) {
    .image-container {
        left: 11vw;
    }
}

@media screen and (min-width: 750px) and (max-width: 1024px) {
    .image-container {
        left: 9vw;
    }
}
