.subscribe-container {
    position: relative;
    background-color: #edf2f7;
    padding-top: 70px;
    padding-bottom: 70px;
}

.email-container {
    display: flex;
    align-items: center;
    width: 85%;
}

#email-input {
    width: 60%;
    margin-right: 10px;
    padding: 14px 24px !important;
    color: #305C6E;
}

#email-input::placeholder {
    color: #305C6E;
}

.subscription-vertical-triangle-container {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
}

.subscription-horizontal-triangle-container {
    position: absolute;
    right: 0;
    bottom: 0;
}

.vertical-triangle {
    z-index: 900;
    border-left-width: calc(5vw + 60px);
    border-left-style: solid;
    border-left-color: transparent;
    border-bottom-style: solid;
    border-bottom-color: #C6B166;
}

.horizontal-triangle {
    z-index: 999;
    border-left-width: calc(20vw + 80px);
    border-left-style: solid;
    border-left-color: transparent;
    border-bottom-width: calc(5vw + 60px);
    border-bottom-style: solid;
    border-bottom-color: rgba(40, 40, 40, 0.2);
}

#mobile-subscribe-button {
    display: none;
}

@media screen and (max-width: 1024px) {
    .subscribe-container {
        display: flex;
        justify-content: center;
        padding-top: 45px;
        padding-bottom: 55px;
        background-color: #F3F5F8;
    }

    .email-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
    }

    #email-input {
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
        font-size: 14px;
        padding-top: 10px !important;
        padding-bottom: 9px !important;
    }

    #mobile-subscribe-button {
        display: block;
        z-index: 1000;
        position: absolute;
        right: 0;
        top: 0;
    }

    #subscribe-button {
        display: none;
    }

    .subscription-vertical-triangle-container {
        height: auto;
    }

    .horizontal-triangle {
        border-left-width: calc(20vw + 20px);
        border-bottom-width: calc(5vw + 20px);
    }

    .subscription-vertical-triangle-container .vertical-triangle {
        border-bottom-width: calc(20vw + 20px) !important;
        border-left-width: calc(5vw + 20px);
    }
}
