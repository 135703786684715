.presentation-container {
    background-image: linear-gradient(#edf2f7, #ffffff);
    padding-top: 100px;
    padding-bottom: 100px;
}

.location-container {
    display: grid;
    user-select: none;
    grid-template-columns: auto auto;
    margin-top: 50px;
    margin-bottom: 300px;
}

/*.location-container .info-section-container {*/
/*    width: 42vw;*/
/*}*/

.location-container .section-title {
    font-size: 48px;
    line-height: 72px;
}

#location-column {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

#grid-location-box {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

#location-paragraph {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: -40px;
}

#desktop-globe-container {
    width: 30vw;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    display: flex;
    justify-content: center;
}

.location-info-container {
    margin-right: 50px;
}

.location-box {
    background-color: white;
    padding-left: 10px;
    padding-right: 5px;
    border-radius: 16px;
    overflow: hidden;
}

#location-box-header-container {
    background-color: white;
    padding: 5px 5px 5px 0;
    position: relative;
    user-select: none;
    cursor: grab;
}

.line {
    border-bottom: 1px solid rgba(160,160,255, 0.3);
    margin-left: -10px;
    margin-right: -5px;
}

.box-header-termination {
    position: absolute;
    background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 60%,rgba(255,255,255,1) 100%);
    bottom: 0;
    height: 100%;
    width: 30px;
    z-index: 999;
}

#box-header-end {
    right: -5px;
    width: 35px;
}

#box-header-start {
    left: -10px;
    transform: rotate(180deg);
}

#location-box-header {
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 12px 0;
    user-select: none;
}

.location-header-title {
    margin: 0 20px;
    padding: 0;
    text-decoration: none;
    font-weight: 600;
    user-select: none;
    text-transform: uppercase;
}

.location-header-title.active {
    color: #A9955E;
}

#location-box-description {
    background-color: white;
    padding: 5px 40px 10px 20px;
    font-weight: 500;
    line-height: 25px;
}

#location-description {
    font-size: 14px;
    padding-top: 30px;
    padding-bottom: 20px;
    font-weight: 500;
    line-height: 25px;
}

.box-image-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    flex-wrap: nowrap;
    width: 100%;
    padding-top: 10px;
}
.homepage-description{
    width: 70%;
}
.homepage-monaco{
    width: 95% !important;
}
.box-image {
    max-width: 65px;
    margin-right: 16px;
}

.reason-container {
    display: flex;
    justify-content: space-between;
    margin: 100px -11vw;
}

.reason-container-details {
    width: 30vw;
}

.reason-container-details .section-title {
    font-size: 56px;
    line-height: 84px;
}

.reason-section-image {
    width: 30vw;
}

.left-image {
    border-radius: 0 400px 400px 0;
}

#reason-left-image {
    align-self: flex-start;
    margin-top: -100px;
}

#reason-right-image {
    border-radius: 400px 0 0 400px;
    align-self: flex-end;
    margin-bottom: -40px;
}

.results-container {
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
}

.results-container .section-title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 400;
}

.results-info-container {
    width: 29vw;
}

.results-logos-container {
    margin-left: 50px;
    margin-top: -30px;
    display: flex;
    flex-direction: column;
    max-width: 636px;
}

.results-logo-table {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: #FFFFFF;
}

.school-logo-wrapper {
    padding: 0;
    margin: -2px;
    width: 200px;
    height: 172px;
}

.school-logo {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    height: 100%;
    filter: grayscale(100%);
}
.school-logo:hover{
    filter: none;
}

.presentation-button {
    margin-top: 30px;
}

.reason-container-details .presentation-button {
    font-size: 18px;
}

.logos-paragraph {
    margin: 20px 0;
    font-size: 14px;
    line-height: 40px;
    font-weight: 500;
}

#location-nav-header {
    position: relative;
    user-select: none;
}

#mobile-globe-container {
    display: none;
}

#mobile-globe-container #globe {
    display: none;
}

.presentation-info-section-container {
    width: 35vw;
}

#reason-title-mobile-container {
    display: none;
}

@media screen and (max-width: 1200px) {
    .presentation-container {
        padding-bottom: 30px;
    }

    .results-container {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 0;
        width: 100%;
    }

    .results-info-container {
        width: 90%;
        align-self: center;
    }

    .results-logos-container {
        margin-left: 0;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        max-width: none;
        width: 90%;
        align-self: center;
    }

    #contact-consultants-desktop {
        display: none;
    }

    /*.reason-container {*/
    /*    margin: 300px -12vw 200px -12vw;*/
    /*}*/

    .reason-container-details {
        width: 50vw;
    }

    #reason-left-image {
        margin-top: -170px;
    }

    #reason-right-image {
        margin-bottom: -200px;
    }

    .location-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #desktop-globe-container {
        width: 100%;
        margin-bottom: 20px;
        grid-column: unset;
        grid-row: unset;
    }

}

@media screen and (max-width: 1024px) {
    .school-logo{
        filter: none;
    }
    .reason-container-details .presentation-button {
        font-size: 16px;
    }
    .presentation-container {
        padding-top: 0;
        background-image: none;
    }

    #location-paragraph {
        margin-right: 0;
    }

    .results-logo-table {
        grid-template-columns: auto auto;
    }

    #reason-right-image {
        display: none;
    }

    .reason-container {
        display: flex;
        flex-direction: column;
        margin: 70px 0;
        align-items: center;
    }

    .reason-section-image {
        width: 40vw;
    }

    #reason-left-image {
        display: none;
    }

    #reason-title {
        display: none;
    }

    #reason-title-mobile-container {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    #reason-left-image-mobile {
        margin-left: -12vw;
    }

    #reason-title-mobile {
        margin-left: 10px;
    }

    .reason-container-details {
        width: 90%
    }

    .reason-container *, *::before, *::after {
         box-sizing: border-box;
    }

    .location-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 70px;
        margin-top: 0;
    }

    .location-info-container {
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #location-box-header-container {
        margin-bottom: 16px;
        width: 100vw;
        padding: 0;
    }

    #location-box-header {
        background-color: white;
        padding: 16px 0;
    }

    #location-box-line {
        display: none;
    }

    .location-box {
        background: none;
        padding: 0;
        width: 100%;
        border-radius: 0;
        overflow: unset;
    }

    .location-box *, *:before, *:after {
        box-sizing: border-box;
    }

    .box-header-termination {
        display: none;
    }

    .location-header-title {
        padding: 0;
        margin: 0 15px;
    }

    #location-box-description {
        border-radius: 8px;
        margin: 0;
        padding: 20px 15px;
    }

    .box-image-container {
        padding-bottom: 20px;

        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 30px;
    }

    .box-image {
        max-width: 15vw;
        max-height: 15vw;
        min-width: 64px;
        min-height: 64px;
    }

    #location-description {
        padding: 0;
        font-size: 16px;
        line-height: 28px;
    }

    .info-section-container.mobile-info-container {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .logos-paragraph {
        margin: 20px 0;
        font-size: 12px;
    }

    .school-logo-wrapper {
        width: auto;
        height: auto;
    }

}
