.team-container {
    padding-top: 50px;
    padding-bottom: 80px;
    background-color: #ffffff;
}

.team-container .section-title {
    font-size: 36px;
    line-height: 54px;
    font-weight: 600;
}

.team-container *, *::before, *::after {
    box-sizing: border-box;
}

.team-container .title-container {
    width: 64vw;
}

#teams {
    padding-bottom: 20px;
}

.team-header-container {
    padding-top: 10px;
    margin-left: -1rem;
    position: relative;
    user-select: none;
    cursor: grab;
}

.team-header {
    font-size: 16px;
    margin-right: 3vw;
    white-space: nowrap;
    text-decoration: none;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.team-header.active {
    color: #A9955E;
}

#team-nav-header {
    position: relative;
    user-select: none;
}

#team-box-header {
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    user-select: none;
    margin-bottom: 30px;
}

.padded-title {
    padding-top: 10px;
    padding-bottom: 10px;
}

.persons-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding-right: 30px;
    width: 75vw;
    grid-row-gap: 40px;
}

@media screen and (max-width: 1200px) {
    .persons-container {
        padding-left: 0;
        padding-right: 0;
    }
}

.person-description-container {
    text-align: center;
    max-width: 20vw;
    min-width: 17vw;
}

.person-description-container p {
    color: #305C6E;
}

.person-avatar {
    border-radius: 50%;
    width: 120px;
    height: 120px;
}

.person-name {
    text-transform: uppercase;
}

.person-description {
    margin-bottom: 0;
    margin-top: 10px;
}

.tab-content > .active {
    display: grid;
}

#team-content {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .team-container {
        padding-top: 0;
        padding-bottom: 50px;
        background: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .team-container .section-title {
        font-size: 28px;
        line-height: 42px;
    }

    #teams {
        background-color: white;
        width: 100vw;
        padding-bottom: 50px;
    }

    .team-header-container {
        margin-left: 0;
        padding-top: 20px;
    }

    .persons-container {
        grid-template-columns: auto auto;
        grid-row-gap: 36px;
        grid-column-gap: 10px;
        width: 90%;
        justify-items: center;
        margin-top: 0;
    }

    .person-description-container {
        max-width: none;
    }

    .title-container {
        width: 90%;
    }

    .team-container .title-container {
        width: 90%;
    }

    .team-header {
        font-size: 14px;
    }

    .person-description {
        font-size: 10px;
    }

    .person-name {
        font-size: 12px;
    }
}
