.horizontal-logos-container {
    position: relative;
    overflow: hidden;
    height: 15vw;
    max-height: 162px;
}

@media screen and (min-width: 1700px) {
    .horizontal-logos-container {
        max-height: 200px;
    }
}

#scrollable-logos {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    animation: bannermove 40s linear infinite;
}

#scrollable-logos:hover {
    animation-play-state: paused;
}

@keyframes bannermove {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-50%, 0);
    }
}

.horizontal-logo {
    margin-right: 1px;
    height: 100%;
    filter: grayscale(100%);
}

.horizontal-logo:hover {
    filter: none;
}

@media screen and (max-width: 1024px) {
    .horizontal-logos-container {
        height: 30vw;
    }
}

